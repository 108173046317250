
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  card:{
    borderRadius:'15px',
    border:'1px solid #CED0E0',
    height:'100%', 
    boxShadow: "none", 
    '&:hover': {
        cursor:'pointer',
        backgroundColor:'#EEEDF4',
        border:'1px solid rgba(89,78,148,0.6)',
    },
   },
    contactuslink:{
      color:'#FFE69D',
      "&:hover": {
       cursor:'pointer'
       },
    },
    link:{
      "&:hover": {
        fontWeight:800
        },
    },
    innerHtml:{
      "& a":{
        transition: 'all 0.5s ease',
        color: 'inherit',
        textDecoration: 'none',
        borderBottom: '1px solid currentColor'
      },
      "& a:hover":{
        background: '#FFCE3B',
      }
    }
});

export default useStyles;