import React, { useEffect, useRef } from 'react';
import { Typography, Box, Link, Grid, Container, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { navigate } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Fade from '@mui/material/Fade';


const Hero = ({pageContext, language}) => {

    const isInitialMount = useRef(true);

    useEffect(() => {   
      if (isInitialMount.current) {
        isInitialMount.current = false;
     } else {
      if(language.english){
        navigate(`/news/${pageContext.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s!?]/g,'').split(' ').join('-').toLowerCase()}`)
       }else{
        navigate(`/nuacht/${pageContext.news_acf.titleIrish.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s!?]/g,'').split(' ').join('-').toLowerCase()}`)
       }
     }
    },[language.english,pageContext.news_acf.slug,pageContext.news_acf.slugIrish]);

    const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

    const color1 = pageContext?.news_acf?.heroBackgroundColour;
    const topColorPercent = matchesSm ? '82%' :'70%';
    const bottomColorPercent = matchesSm ? '18%' :'30%';

    return (
        <Grid container role="banner" aria-label="hero banner" style={{background: `linear-gradient(to bottom,  ${color1} ${topColorPercent},#FFFFFF ${bottomColorPercent})`}}>
            <Container maxWidth="lg">
                <Grid item container pt={8} pb={4}>
                    <Grid item xs={12} sm={3} pt={2}>
                    <Link href={language.english ? "/news" : "/nuacht"} color="inherit" style={{textDecoration: 'none'}}>
                        <Button id="main" aria-label="news button" variant="contained" color="inherit">
                            <ArrowBackIcon size="small" />
                            <Typography>{language.english ? 'News' : 'Nuacht'}</Typography>
                        </Button>
                    </Link>
                    </Grid> 
                    <Grid item xs={12} sm={9} pt={matchesSm ? 6 : undefined}>
                        <Typography variant="heading" component="h1" style={{lineHeight:'120%'}} fontSize={matchesSm ? '32px' : undefined} color={pageContext.news_acf.heroFontColorBlack === true ? "black" : "white"}>
                        {language.english ? pageContext?.title : pageContext.news_acf.titleIrish}
                        </Typography>
                        <Typography style={{fontStyle:'italic', fontSize: "24px", fontWeight: 400}} color={pageContext.news_acf.heroFontColorBlack === true ? "black" : "white"}>
                            {language.english ? pageContext?.news_acf?.caption : pageContext?.news_acf?.captionIrish}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} pl={2} pr={2}>
                    <Box style={{position:'relative'}}>
                    <Fade in={true}>
                        <Box>
                            <GatsbyImage imgStyle={{maxWidth:'100%'}} image={getImage(pageContext?.news_acf?.heroImage?.localFile?.childImageSharp)} alt={language.english ? pageContext?.news_acf?.heroImage?.altText : pageContext?.news_acf?.heroImage?.altTextIrish?.irishAlt} />
                        </Box>
                    </Fade>
                    </Box>
                </Grid>
            </Container>
        </Grid>
    )}

export default Hero;