import React from 'react';
import { Grid, Box, Container, Typography } from '@mui/material';
import {  Link, graphql, StaticQuery } from 'gatsby';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const MainContent = ({pageContext, language}) => {

  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

    const classes = useStyles();

    return(
    <StaticQuery
      query={graphql`{
        allWpNews(limit: 4){
        nodes{
            title
            date
            news_acf{
              titleIrish
              caption
              captionIrish
              contentirish
              content
              heroBackgroundColour
              heroFontColorBlack
                      heroImage {
                altText
                altTextIrish{
                irishAlt}
                localFile{
                  childImageSharp {
                  gatsbyImageData( 
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                  )
                }
                }
               }
             }
           }
         }
        }
      `}
      render={data => (
    <Box role="main" aria-label="main content">
    <Container maxWidth="md">
        <Grid container pt={2} pb={4}>
            <Grid item xs={12} pt={2} display="flex" justifyContent="center">
                <Box pt={4}>
                    <Typography variant="body" className={classes.innerHtml} style={{fontSize:matchesSm ? '18px' : undefined, lineHeight:matchesSm ? '140%' : undefined,}} dangerouslySetInnerHTML={{__html:  language.english ? pageContext?.news_acf?.content  : pageContext?.news_acf?.contentirish}} />    
                </Box>
            </Grid>
        </Grid>
    </Container>
        <Grid container style={{backgroundColor:'#E6E4EF'}}>
            <Grid item xs={12} mb={4}>
              <Grid container pl={4} pr={4}>
                <Grid item xs={12} mt={10} mb={3} display="flex" justifyContent="center">
                    <Box>
                      <Typography variant="bodyheading" color="#594E94" component="h2">Recent News</Typography>
                    </Box>
                </Grid>
                {data.allWpNews.nodes.map((item, index) => {
                    let date = new Date(item.date).toDateString();
                    let irishDate;
                    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
                    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                    const daysGA = ['Lua', 'Mha', 'Che', 'Dea', 'Aoi', 'Sat', 'Dom'];
                    const monthsGA = ['Ean', 'Fea', 'Mar', 'Aib', 'Bea', 'Mei', 'Lui', 'Lun', 'Mea', 'Dei', 'Sam', 'Nol'];
                    if(!language.english){
                      let day = date.substring(0,3);
                      let month = date.substring(4,7);
                      let newDay;
                      let newMonth;
                      for(let i = 0; i<days.length; i++){
                        if(day === days[i]){
                          newDay = daysGA[i];
                        }
                      }
                      for(let j = 0; j<days.length; j++){
                        if(month === months[j]){
                          newMonth = monthsGA[j];
                        }
                      }
                    let date1 = date.replace(`${day}`, `${newDay}`);
                    irishDate = date1.replace(`${month}`, `${newMonth}`);
                    }
                    return(
                    <Grid key={index} item xs={12} sm={6} md={4} lg={3} p={2} pl={2} pr={2} pb={4}>
                    <Link to={language.english ? `/news/${item.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s!?]/g,'').split(' ').join('-').toLowerCase()}` : `/nuacht/${item.news_acf.titleIrish.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s!?]/g,'').split(' ').join('-').toLowerCase()}`} color="inherit" style={{textDecoration: 'none'}}>
                        <Card className={classes.card}>
                        <CardMedia height="140"><GatsbyImage style={{height:'200px'}} imgStyle={{borderRadius:'15px 15px 0px 0px'}} image={getImage(item.news_acf?.heroImage?.localFile?.childImageSharp)} alt="" /></CardMedia>
                        <Box p={1}>
                        <CardContent>
                            <Typography gutterBottom variant="cardheading2" component="h3">
                            {item.title}
                            </Typography>
                            <br />
                            <Typography variant="card2" color="text.secondary" >{language.english ? date.substring(4,15) : irishDate.substring(4,15)}</Typography>
                        </CardContent>
                        </Box>
                        </Card>
                    </Link>
                    </Grid>
                    )
                })}
            </Grid>
          </Grid>
        </Grid>
   </Box>
      )}
    />
    )
}


    

export default MainContent;
