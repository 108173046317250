import React, {useState, useEffect} from 'react';
import {IconButton, Box, Grid} from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Tooltip from '@mui/material/Tooltip';
import useStyles from './styles';

const Scroll = ({showBelow}) => {

    const classes = useStyles();

    const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

    const [show, setShow] = useState(showBelow ? false : true);

    const [value, setValue] = useState(0);

    const handleScroll = () => {
        if (window.pageYOffset > showBelow){
            if(!show) setShow(true)
        }else{
            if(show) setShow(false)
        }
    }

    useEffect(() => {
        if(showBelow){
            window.addEventListener(`scroll`, handleScroll)
            return () => window.removeEventListener(`scroll`, handleScroll)
        }
    })
    const handleClick = () => {
        window[`scrollTo`]({top:0, behavior:`smooth`});
        setTimeout(() => {
            document.getElementById('main').focus();
        },1100)
      
    }

    return (
        <Grid container item xs={12} display="flex" justifyContent="flex-end">
            <BottomNavigation
            style={{
                height:'125px',
                paddingRight:matchesSm ? '40px' : '10px',
                zIndex:1000,
                backgroundColor:'transparent',
                width: '15%',
                position: 'fixed',
                bottom: 0,
              }}
                showLabels
                value={value}
                onChange={(event, newValue) => {
                setValue(newValue);
                }}
            >
                <Box style={{width:matchesSm ? '90%' : '98%'}} display="flex" justifyContent="center">
                {show &&
                 <Tooltip arrow title="Back to top" placement="top">
                    <IconButton tabIndex="0" onClick={() => {handleClick();}} className={classes.backtotop}>
                        <ArrowUpwardIcon fontSize="large" />
                    </IconButton>
                </Tooltip>}
                </Box>
            </BottomNavigation>
        </Grid>
    )
}

export default Scroll;