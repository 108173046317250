
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
   backtotop:{
    backgroundColor: '#F5F2E7', 
    height:'70px', 
    width:'70px',
    marginBottom:'15px', 
    border:'1px solid #A39161',
    "&:hover": { backgroundColor: "#EDE8D4" }
   }
});

export default useStyles;